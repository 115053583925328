//
// dropdown.scss
//

.dropdown-menu {
    min-width: 15rem;
    box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    position: absolute;
    z-index: 1000;
    border-radius: 0;
    background-color: $body-bg;
    border-color: $body-bg;

    &.show {
        top: 100% !important;
    }

    i {
        display: inline-block;
    }
}

.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
}

.dropdown, .dropleft, .dropright, .dropup {
    z-index: auto;
}

.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=left] {
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        transform: translateY(0)
    }

    0% {
        transform: translateY(10px)
    }
}


// Dropdown Large (Custom)

@media (min-width: 600px) {
    .dropdown-lg {
        width: $dropdown-lg-width;
    }
}

// Dropdown Mega Menu

.dropdown-mega {
    position: static !important;
}

.dropdown-megamenu {
    padding: 20px;
    left: 20px !important;
    right: 20px !important;
    background-image: url("../images/megamenu-bg.png");
    background-position: right bottom;
    background-repeat: no-repeat;
}

.megamenu-list {
    li {
        padding: 5px 20px 5px 15px;
        position: relative;

        a {
            color: $dropdown-link-color;

            &:hover {
                color: $primary;
            }
        }

        &:before {
            content: "\F0130";
            position: absolute;
            left: 0;
            font-family: "Material Design Icons";
            font-size: 9px;
            line-height: 22px;
        }
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $dropdown-link-color;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
    }
}

// Dropdown size
@include media-breakpoint-up(lg) {
    .dropdown-mega-menu-xl {
        width: 40rem;
    }

    .dropdown-mega-menu-lg {
        width: 26rem;
    }
}


// Bootstrap dropdown
.dropdown-item {
    // background-color: $input-bg;
    font-weight: $font-weight-light;
}
