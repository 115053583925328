//
// forms.scss
//


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $gray-600 !important;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $gray-600 !important;
}

:-ms-input-placeholder { /* IE 10+ */
    color: $gray-600 !important;
}

:-moz-placeholder { /* Firefox 18- */
    color: $gray-600 !important;
}

.form-border-noleft {
    border-left: 0px;
    border-radius: 0 $input-border-radius $input-border-radius 0;
}

.form-border-noleft-right {
    border-radius: $input-border-radius 0 0 $input-border-radius;
}

.form-border-notop {
    border-top: 0px;
    border-radius: 0 0 $input-border-radius $input-border-radius;
}

.form-control,
.custom-select {
    box-shadow: $components-shadow-sm;
    background-color: $input-bg;
    border-color: $input-border-color;
    border-radius: $input-border-radius;
}

.custom-select {
    cursor: pointer;
    background-size: 30px 30px;
    border-width: 1px;
}

// Focus
.form-control:focus {
    border-color: $input-border-color;
    background-color: $light;
    border: $input-border-width solid $input-focus-border-color !important;
}

// Form-control light
.form-control-light {
    background-color: $input-light-bg !important;
    border-color: $input-light-bg !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid, .custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid, .form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

.custom-control-label {
    cursor: pointer;
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $input-border-color;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }

    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $input-border-color;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Search box
.search-bar {

    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: inherit;
        z-index: 10;
        font-size: 14px;
        font-weight: bold;
        left: 13px;
        top: 0;
        color: $body-color;
    }
}

// Show Password
.password-eye {
    &:before {
        font-family: feather !important;
        content: "\e86a";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\e86e";
        }
    }
}

// Select
select[multiple] option + option,
select[size] option + option {
    margin-top: 1px;
}

select[multiple] option,
select[size] option {
    padding: 6px 12px;
    border-radius: 2px;
}

// Input group prepend
.input-group-text {
    font-weight: $label-font-weight !important;
    background-color: $input-bg;
}

// Label

label {
    font-weight: $label-font-weight !important;
    font-size: 100% !important;
}

// override Chrome calendar icon
::-webkit-calendar-picker-indicator {
    filter: invert(50%);
}

// floating forms
.form-floating {
    position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
    height: calc(4rem + 2px);
    line-height: 1.25;
}

.form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    font-size: 0.9em !important;
}

@media (prefers-reduced-motion: reduce) {
    .form-floating > label {
        transition: none;
    }
}

.form-floating > .form-control {
    padding: 1rem 0.75rem;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: $input-border-radius;
    margin-top: $input-border-radius-lg;
}

.form-floating > .form-control::-moz-placeholder {
    color: transparent !important;
    opacity: 0;
}

.form-floating > .form-control::placeholder {
    color: transparent !important;
    opacity: 0;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

// form-row flex adj

.form-row .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(0.5rem) translateX(0.5rem);
}

.form-row .form-floating > .form-control:focus ~ label,
.form-row .form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-row .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(0.5rem) translateX(0.5rem);
}

.form-row .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(0.5rem) translateX(0.5rem);
}


// overrides

.form-floating {
    position: relative;

    > .form-control,
    > .form-select {
        height: calc(4rem + 2px);
        line-height: 1.25;
    }

    > label {
        position: absolute;
        font-size: 0.9em !important;
        top: 0;
        left: 0;
        height: 100%; // allow textareas
        padding: 1rem 0.75rem;
        pointer-events: none;
        border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
        transform-origin: 0 0;
        @include transition(opacity 0.1s ease-in-out, transform 0.1s ease-in-out);
    }
    // stylelint-disable no-duplicate-selectors
    > .form-control {
        padding: 1rem 0.75rem;

        &::placeholder {
            color: transparent !important;
        }

        &:focus,
        &:not(:placeholder-shown) {
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
        }
        // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
        &:-webkit-autofill {
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
        }
    }

    > .form-select {
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;
    }

    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-select {
        ~ label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
        ~ label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
    }
    // stylelint-enable no-duplicate-selectors
}


// form-row flex adj overrides with duplicates

.form-row .form-floating {
    position: relative;

    > label {
        padding: 2rem 1rem;
    }
    // stylelint-disable no-duplicate-selectors
    > .form-control {
        padding: 1rem 0.75rem;

        &::placeholder {
            color: transparent !important;
        }

        &:focus,
        &:not(:placeholder-shown) {
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
        }
        // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
        &:-webkit-autofill {
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
        }
    }

    > .form-select {
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;
    }

    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-select {
        ~ label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(.2rem);
        }
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
        ~ label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
    }
    // stylelint-enable no-duplicate-selectors
}

.select2-selection {
    padding-bottom: 15px !important;
    padding-top: 10px !important;
    margin-top: 0px !important;
}

.select2-container .select2-search--inline .select2-search__field {
    font-family: inherit !important;
    height: 26px !important;
    margin-left: 14px!important;
    font-weight: bold;
}

.select2-results__option {
    padding: 6px 12px !important;
    font-size: 1em !important;
}

