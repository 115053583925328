//
// _badges.scss
//


.badge {
    box-shadow: $components-shadow;
}

.badge-instrument {
    font-size: 1rem;
    line-height: 1.66rem;
}

// Lighten badge
@mixin badge-variant-light($bg) {
    color: $bg;
    background-color: rgba($bg, 0.18);
    box-shadow: none;

    &[href] {
        @include hover-focus {
            color: $bg;
            text-decoration: none;
            background-color: rgba($bg, 0.4);
        }
    }
}

// Outline badge

@mixin badge-variant-outline($bg) {
    color: $bg;
    border: 1px solid $bg;
    background-color: transparent;
    box-shadow: none;

    &[href] {
        @include hover-focus {
            color: $bg;
            text-decoration: none;
            background-color: rgba($bg, 0.2);
        }
    }
}

// Lighten badge (soft)

@each $color, $value in $theme-colors {
    .badge-soft-#{$color} {
        @include badge-variant-light($value);
    }
}

// Outline badge

@each $color, $value in $theme-colors {
    .badge-outline-#{$color} {
        @include badge-variant-outline($value);
    }
}


@include media-breakpoint-down(md) {
    .badge-instrument {
        display: none !important;
    }
}
