// Variables
//
// custom-variables
//


// Set main background from start
$main: #ffffff;

//Background left-sidebar
$bg-leftbar: #fdfdfd;
$bg-leftbar-dark: #000000;
$bg-leftbar-brand: #3452ff;
$bg-leftbar-gradient: #3452ff;

// Leftbar width 240 original
$leftbar-width: 240px;
$leftbar-width-condensed: 70px;

// Small Leftbar Width
$leftbar-width-sm: 160px;

// Boxed layout width
$boxed-layout-width: 1300px;

// Logo
$bg-logo: $main;

// Compact sidebar
$twocolumn-sidebar-width: 220px;
$twocolumn-sidebar-iconview-width: 70px;
$twocolumn-sidebar-bg: #ffffff;
$twocolumn-sidebar-iconview-bg: #000000;

// Dark leftbar menu color
$menu-item-color-dark: #777777;
$menu-item-hover-color-dark: #ffffff;
$menu-item-active-color-dark: #ffffff;

//Menu item
$menu-item: #363636;
$menu-item-hover: #000000;
$menu-item-active: #000000;
$menu-sub-item-active: #000000;
$menu-item-size: .84rem;
$menu-sub-item-size: .84rem;

// Horizontal Menu Item
$hori-menu-item-color: #6e7488;

//Rightbar Width
$rightbar-width: 270px;
$rightbar-bg: #ffffff;

//Topbar Background
$bg-topbar-light: #ffffff;
$bg-topbar-dark: #ffffff;

// Leftbar item shadow (Condensed item)
$box-shadow-condensed: 0 1px 7px 0 rgba(154,161,171,.2);

// Topnav Height (Horizontal)
$topnav-height: 55px;

// Topbar Height
$topbar-height: 70px;

// Horizontal Layout
$horizontal-layout-width: 90%;

// Body minimum height
$body-min-height: 0px; //1550px;


// Secondary font
$font-family-secondary: 'Public Sans',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

// Font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-light: 300;

// Dropdown Large
$dropdown-lg-width: 88%;

// Logo Display
$logo-dark-display: none;
$logo-light-display: block;

// Logo Display - Auth Pages
$logo-auth-dark-display: block;
$logo-auth-light-display: none;

// User authentication Background
$auth-bg: $white;
$auth-bg-alt: #6658dd;
$auth-img-bg: url("../images/bg-auth.png");
$auth-img-pattern-bg: url("../images/bg-pattern.png");

// Apex chart
$apex-grid-color: #f9f9fd;

// Chat widget
$chat-primary-user-bg: #f1f5f7;
$chat-secondary-user-bg: #d5eef7;

// Text-color (h1 to h6)
$text-color-h: #000000;

// Card overlay
$card-overlay-color: #3e4852;
$card-overlay-bg: rgba(#ffffff, 0.8);

// Input light gray
$input-light-bg: #f3f7f9;

// Shadow
$shadow-sm: 0 0px 8px 0 rgba(154,161,171,.15);
$shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
$shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);


// Components Shadows
$components-shadow-sm: none;
$components-shadow: none;
$components-shadow-lg: none;

// label
$label-font-weight: 300;

// Table Head
$table-head-font-weight: $font-weight-semibold;

//
// Color system
//

$white: #fff;
$gray-100: #ebebeb;
$gray-200: #cecece;
$gray-300: #b2b2b2;
$gray-400: #969696;
$gray-500: #808080;
$gray-600: #6c6c6c;
$gray-700: #515151;
$gray-800: #363636;
$gray-900: #1e1e1e;

$black: #000;

$bg-topbar-dark-text: #fff;
$bg-topbar-light-text: #fff;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge( ( "100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, "900": $gray-900 ), $grays );

$blue: #000000;
$indigo: #3366FF;
$purple: #6559cc;
$pink: #ff00e1;
$red: #ea3943; // rgba(226, 54, 66, 1);
$orange: #fd7e14;
$yellow: rgba(252, 169, 10, 1);
$green: #30D5C8; // rgba(48, 213, 200, 1);
$teal: #02a8b5;
$cyan: #007acc;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge( ( "blue": $blue, "indigo": $indigo, "purple": $purple, "pink": $pink, "red": $red, "orange": $orange, "yellow": $yellow, "green": $green, "teal": $teal, "cyan": $cyan, "white": $white, "gray": $gray-600, "gray-dark": $gray-800 ), $colors );

$primary: $black;
$secondary: $gray-300;
$success: $green;
$info: $green;
$warning: $yellow;
$danger: $red;
$purple: $purple;
$pink: $pink;
$light: $white;
$dark: $gray-900;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge( ( "primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark, "pink": $pink, "purple": $purple ), $theme-colors);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-800;
$yiq-text-light: $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: ( ("<","%3c"), (">","%3e"), ("#","%23"), );


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-hover-media-query: false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.5rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge( ( 0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3) ), $spacers );

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge( ( 25: 25%, 50: 50%, 75: 75%, 100: 100%, auto: auto ), $sizes );


// Body
//
// Settings for the `<body>` element #f5f6f8.

$body-bg: $white;
$body-color: $gray-700;


// Links
//
// Style anchor elements.

$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: ( xs: 0, sm: 360px, md: 768px, lg: 992px, xl: 1367px );

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px );

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 24px;
$grid-row-columns: 6;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: lighten($gray-200,2.5%);

$border-radius: .25rem;
$border-radius-lg: .75rem; // .3rem
$border-radius-sm: .2rem; // .2rem

$rounded-pill: 50rem;

$box-shadow-sm: 0 0px 8px 0 rgba(154,161,171,.15);
$box-shadow: 0 30px 30px 0 rgba(154,161,171,.3);
$box-shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);

$component-active-color: $white;
$component-active-bg: theme-color("info");

$caret-width: .3em;
$caret-vertical-align: $caret-width * .85;
$caret-spacing: $caret-width * .85;

$transition-base: all .2s ease-in-out;
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join( ( (21 9), (16 9), (4 3), (1 1), ), $embed-responsive-aspect-ratios );

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Public Sans',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
$font-family-monospace: 'Public Sans',SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .9;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 800;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 0.9375rem;
$h6-font-size: 0.75rem;



$headings-margin-bottom: $spacer;
$headings-font-family: null;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-color: null;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 0.75rem;

$text-muted: $gray-600;

$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: lighten($gray-300,2.5%);
$hr-border-width: $border-width;

$mark-padding: .2em;

$dt-font-weight: $font-weight-medium;

$kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 6px;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: .85rem;
$table-cell-padding-sm: .5rem;

$table-color: $black;
$table-bg: $white;
$table-accent-bg: $body-bg;
$table-hover-color: $table-color;
$table-hover-bg: $gray-300;
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: lighten($gray-300,5%);

$table-head-bg: $white;
$table-head-color: $gray-700;

$table-dark-color: $gray-600;
$table-dark-bg: $gray-900;
$table-dark-accent-bg: rgba($white, .05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, .075);
$table-dark-border-color: lighten($gray-900, 7.5%);

$table-striped-order: odd;
$table-striped-color: $body-bg;

$table-caption-color: $text-muted;

$table-bg-level: -9;
$table-border-level: -6;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: .45rem;
$input-btn-padding-x: .90rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: .15rem;
$input-btn-focus-color: rgba($component-active-bg, .25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: .28rem;
$input-btn-padding-x-sm: .8rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: .5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: 0 0 0;
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: .65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125);

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: .15rem;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;


// Forms

$label-margin-bottom: .5rem;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-semibold;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $gray-100;
$input-disabled-bg: $gray-100;

$input-color: $black;
$input-border-color: $gray-100;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 1px 1px rgba($black, .075);

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $body-bg;
$input-focus-border-color: $info;// darken($input-border-color,10%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: none;

$input-placeholder-color: $gray-500;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter: add($input-line-height * .25em, $input-padding-y / 2);

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm: add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg: add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top: .25rem;

$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: .3rem;
$form-check-input-margin-x: .25rem;

$form-check-inline-margin-x: .75rem;
$form-check-inline-input-margin-x: .3125rem;

$form-grid-gutter-width: 10px;
$form-group-margin-bottom: 1rem;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;

$custom-forms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$custom-control-gutter: .5rem;
$custom-control-spacer-x: 1rem;
$custom-control-cursor: null;

$custom-control-indicator-size: 1.75rem;
$custom-control-indicator-bg: $input-bg;

$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-label-color: null;

$custom-control-indicator-disabled-bg: $input-disabled-bg;
$custom-control-label-disabled-color: $gray-600;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: rgba(theme-color("primary"), .5);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow;
$custom-control-indicator-focus-border-color: $input-focus-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>");
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>");

$custom-switch-width: $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius: $custom-control-indicator-size / 2;
$custom-switch-indicator-size: subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4);

$custom-select-padding-y: $input-padding-y;
$custom-select-padding-x: $input-padding-x;
$custom-select-font-family: $input-font-family;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 20px 20px; // 8px 10px In pixels because image dimensions
$custom-select-indicator-color: $primary;
$custom-select-indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E"); // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'/></svg>");
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right .4rem center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-position: center right ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, .075);

$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: none;

$custom-select-padding-y-sm: $input-padding-y-sm;
$custom-select-padding-x-sm: $input-padding-x-sm;
$custom-select-font-size-sm: $input-font-size-sm;
$custom-select-height-sm: $input-height-sm;

$custom-select-padding-y-lg: $input-padding-y-lg;
$custom-select-padding-x-lg: $input-padding-x-lg;
$custom-select-font-size-lg: $input-font-size-lg;
$custom-select-height-lg: $input-height-lg;

$custom-range-track-width: 100%;
$custom-range-track-height: .5rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-300;
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: inset 0 .25rem .25rem rgba($black, .1);

$custom-range-thumb-width: 1rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 .1rem .25rem rgba($black, .1);
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg: $gray-500;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: none;
$custom-file-disabled-bg: $input-disabled-bg;

$custom-file-padding-y: $input-padding-y;
$custom-file-padding-x: $input-padding-x;
$custom-file-line-height: $input-line-height;
$custom-file-font-family: $input-font-family;
$custom-file-font-weight: $input-font-weight;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: none;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: ( en: "Browse" );


// Form validation

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color("success");
$form-feedback-invalid-color: theme-color("danger");

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge( ( "valid": ( "color": $form-feedback-valid-color, "icon": $form-feedback-icon-valid ), "invalid": ( "color": $form-feedback-invalid-color, "icon": $form-feedback-icon-invalid ), ), $form-validation-states );

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 0;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;


// Navs

$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-divider-color: $gray-200;
$nav-divider-margin-y: $spacer / 3;


// Navbar

$navbar-padding-y: $spacer / 2;
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: .5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2;

$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, .5);
$navbar-dark-hover-color: rgba($white, .75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .25);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, .1);

$navbar-light-color: rgba($black, .5);
$navbar-light-hover-color: rgba($black, .7);
$navbar-light-active-color: rgba($black, .9);
$navbar-light-disabled-color: rgba($black, .3);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, .1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: .25rem;
$dropdown-spacer: .125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: darken($light, 3%);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 0;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg: $gray-200;
$dropdown-divider-margin-y: $nav-divider-margin-y;
$dropdown-box-shadow: 0 .5rem 1rem rgba($black, .175);

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $body-color;
$dropdown-link-hover-bg: $gray-300;

$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: $gray-400;
$dropdown-link-disabled-color: $gray-500;

$dropdown-item-padding-y: .375rem;
$dropdown-item-padding-x: 1.2rem;

$dropdown-header-color: inherit;


// Pagination

$pagination-padding-y: .5rem;
$pagination-padding-x: .75rem;
$pagination-padding-y-sm: .25rem;
$pagination-padding-x-sm: .5rem;
$pagination-padding-y-lg: .75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;

$pagination-color: $primary;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $gray-300;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $dark;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;


// Jumbotron

$jumbotron-padding: 2rem;
$jumbotron-color: null;
$jumbotron-bg: lighten($gray-300,5%);


// Cards

$card-spacer-y: 1rem;
$card-spacer-x: 1.5rem;
$card-border-width: 1px;
$card-border-radius: .25rem;
$card-border-color: lighten($gray-300,3%);
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-bg: lighten($gray-300, 5%);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: transparent;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: $grid-gutter-width / 2;
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: 1.25rem;


// Tooltips

$tooltip-font-size: $font-size-base;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: 0.2rem;
$tooltip-opacity: .9;
$tooltip-padding-y: .4rem;
$tooltip-padding-x: .8rem;

$tooltip-margin: 0;

$tooltip-arrow-width: .8rem;
$tooltip-arrow-height: .4rem;
$tooltip-arrow-color: $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;


// Popovers

$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: $gray-300;
$popover-border-radius: $border-radius;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow: 0 .25rem .5rem rgba($black, .2);

$popover-header-bg: $gray-100;
$popover-header-color: $headings-color;
$popover-header-padding-y: .7rem;
$popover-header-padding-x: .8rem;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 1rem;
$popover-arrow-height: .5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, .05);


// Toasts

$toast-max-width: 350px;
$toast-padding-x: .75rem;
$toast-padding-y: .25rem;
$toast-font-size: .875rem;
$toast-color: null;
$toast-background-color: rgba($white, .85);
$toast-border-width: 1px;
$toast-border-color: rgba(0, 0, 0, .1);
$toast-border-radius: .25rem;
$toast-box-shadow: 0 .25rem .75rem rgba($black, .1);

$toast-header-color: $gray-700;
$toast-header-background-color: rgba($white, .85);
$toast-header-border-color: rgba(0, 0, 0, .05);


// Badges

$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: .25em;
$badge-padding-x: .4em;
$badge-border-radius: $border-radius;

$badge-transition: $btn-transition;
$badge-focus-width: $input-btn-focus-width;

$badge-pill-padding-x: .6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem;

$modal-dialog-margin: .5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: 0.2rem;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: 0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg: $body-bg;
$modal-backdrop-opacity: .5;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: 1px;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl: 1140px;
$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 300px;

$modal-fade-transform: translate(0, 50%);
$modal-show-transform: none;
$modal-transition: transform .3s ease-out;
$modal-scale-transform: scale(1.02);


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: .75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;


// Progress bars

$progress-height: 0.75rem;
$progress-font-size: $font-size-base * .75;
$progress-bg: lighten($gray-300, 5.5%);
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 .1rem .1rem rgba($black, .1);
$progress-bar-color: $white;
$progress-bar-bg: theme-color("primary");
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width .6s ease;


// List group

$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: rgba($black, .05);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: .75rem;
$list-group-item-padding-x: 1.25rem;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;


// Image thumbnails

$thumbnail-padding: .25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($black, .075);


// Figures

$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;


// Breadcrumbs

$breadcrumb-font-size: null;

$breadcrumb-padding-y: $spacer/1.5;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: .5rem;

$breadcrumb-margin-bottom: 1rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $gray-500;
$breadcrumb-divider: quote("\F0142");

$breadcrumb-border-radius: $border-radius;


// Carousel

$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-hover-opacity: .9;
$carousel-control-transition: opacity .15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $white;
$carousel-indicator-transition: opacity .6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;

$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");

$carousel-transition-duration: .6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Spinners

$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: .25em;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: .2em;


// Close

$close-font-size: 1.2rem;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: none;


// Code

$code-font-size: 87.5%;
$code-color: $black;

$kbd-padding-y: .2rem;
$kbd-padding-x: .4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;


// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;


// Printing

$print-page-size: a3;
$print-body-min-width: map-get($grid-breakpoints, "lg");

// HighCharts


// Colors for data series and points.
$colors: #ff22ff #ff22ff #3452ff #3452ff #3452ff #3452ff #e4d354 #2b908f #f45b5b #91e8e1 !default;

// Chart background, point stroke for markers and columns etc
$background-color: $body-bg !default;

// Neutral colors, grayscale by default. The default colors are defined by mixing the
// background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: $black !default; // Strong text.
$neutral-color-80: $gray-800 !default; // Main text and some strokes.
$neutral-color-60: $gray-600 !default; // Axis labels, axis title, connector fallback.
$neutral-color-40: $gray-400 !default; // Credits text, export menu stroke.
$neutral-color-20: $gray-400 !default; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: $gray-300 !default; // Grid lines etc.
$neutral-color-5: $gray-400 !default; // Minor grid lines etc.
$neutral-color-3: $background-color !default; // Tooltip backgroud, button fills, map null points.

// Colored, shades of blue by default
$highlight-color-100: $red !default; // Drilldown clickable labels, color axis max color.
$highlight-color-80: $yellow !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-60: $cyan !default; // Navigator mask fill.
$highlight-color-20: $gray-400 !default; // Ticks and axis line.
$highlight-color-10: $gray-500 !default; // Pressed button, color axis min color.

// Fonts
$font-family: 'Public Sans',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !default;
$title-font-size: 1.5em !default;
$subtitle-font-size: 1em !default;
$legend-font-size: 1em !default;
$axis-labels-font-size: 0.9em !default;

// Tooltip
$tooltip-border: 1px !default;
$tooltip-background: $neutral-color-3 !default;

// Axes
$xaxis-grid-line: 0px !default;

// Range-selector
$range-selector-button-border: 0px !default;
$range-selector-input-text: $neutral-color-80 !default;
$range-selector-input-border: $neutral-color-20 !default;

// Data-labels
$data-label-color: $neutral-color-80 !default;

// Buttons
$context-button-background: $background-color !default;

$highcharts-button-background: $neutral-color-3 !default;
$highcharts-button-border: $neutral-color-20 !default;
$highcharts-button-text: $neutral-color-80 !default;

$highcharts-button-pressed-background: $highlight-color-10 !default;
$highcharts-button-pressed-border: $neutral-color-20 !default;
$highcharts-button-pressed-text: $neutral-color-80 !default;

$highcharts-button-hover-background: $neutral-color-10 !default;
$highcharts-button-hover-border: $neutral-color-20 !default;
$highcharts-button-hover-text: $neutral-color-80 !default;

// Navigator
$navigator-series-fill: $highlight-color-80 !default;
$navigator-series-border: $highlight-color-80 !default;

// Scrollbar
$scrollbar-track-background: $neutral-color-5 !default;
$scrollbar-track-border: $neutral-color-5 !default;

// No format - reset colors and borders
.no-format {
    color: $body-color !important;
    border: none !important;
}

.highcharts-container {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: $font-family;
    font-size: 12px;
    z-index: 99 !important;
}

.hcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0; /* #1072 */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: $font-family;
    font-size: 12px;
}

.depth-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0; /* #1072 */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: $font-family;
    font-size: 12px;
}


.highcharts-root {
    display: block;
}

.highcharts-root text {
    stroke-width: 0;
}

.highcharts-strong {
    font-weight: bold;
}

.highcharts-emphasized {
    font-style: italic;
}

.highcharts-anchor {
    cursor: pointer;
}

.highcharts-background {
    fill: transparent;
}

.highcharts-plot-border, .highcharts-plot-background {
    fill: none;
}

.highcharts-label-box {
    fill: none;
}

.highcharts-button-box {
    fill: inherit;
}

.highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none;
}

.highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0;
}

/* Datalables */
.highcharts-text-outline {
    stroke-width: 0px;
    stroke: $neutral-color-100;
    fill: $neutral-color-100;
}

/* Titles */
.highcharts-title {
    fill: $neutral-color-80;
    font-size: $h3-font-size;
    font-family: $font-family-secondary;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 20px;
}

.highcharts-subtitle {
    fill: $neutral-color-60;
}

/* Axes */
.highcharts-axis-line {
    fill: none;
    stroke: $highlight-color-20;
}

.highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-axis-title {
    fill: $neutral-color-60;
}

.highcharts-axis-labels {
    fill: $neutral-color-60;
    cursor: default;
    font-size: $axis-labels-font-size;
}

.highcharts-grid-line {
    fill: none;
    stroke: none;
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: $xaxis-grid-line;
}

.highcharts-tick {
    stroke: $highlight-color-20;
}

.highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
}

.highcharts-minor-grid-line {
    stroke: $neutral-color-5;
}

.highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: $neutral-color-20;
}

.highcharts-crosshair-category {
    stroke: $highlight-color-20;
    stroke-opacity: 0.25;
}


/* Credits */
.highcharts-credits {
    cursor: pointer;
    fill: $neutral-color-40;
    font-size: 0.7em;
    transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
    fill: black;
    font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;
}

.highcharts-tooltip text {
    fill: $neutral-color-80;
}

.highcharts-tooltip .highcharts-header {
    font-size: 0.85em;
}

.highcharts-tooltip-box {
    stroke-width: $tooltip-border;
    fill: $tooltip-background;
    fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
    fill: $tooltip-background;
    fill-opacity: 0.85;
}

.highcharts-selection-marker {
    fill: $highlight-color-80;
    fill-opacity: 0.25;
}

.highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
    stroke-width: 6px;
}

.highcharts-state-hover path {
    transition: stroke-width 50; /* quick in */
}

.highcharts-state-normal path {
    transition: stroke-width 250ms; /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
    transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.2;
}

/* Series options */

/* Default colors */


.highcharts-area {
    fill-opacity: 0.05;
    stroke-width: 0;
}

.highcharts-markers {
    stroke-width: 1px;
    stroke: $background-color;
}

.highcharts-point {
    stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
    stroke-width: 0;
}

.highcharts-data-label {
    font-size: 1.1em;
    font-weight: bold;
}

.highcharts-data-label-box {
    fill: inherit;
    stroke-width: 0;
    fill-opacity: 0.1;
}

.highcharts-data-label text, text.highcharts-data-label {
    // fill: $data-label-color;
}

.highcharts-data-label-connector {
    fill: none;
}

.highcharts-pie-series .highcharts-data-label-connector {
    // stroke: $gray-500;
    // stroke-dasharray: 1, 2 !important;
    stroke-width: 0px !important;
    fill: none !important;
}

.highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select {
    fill: $neutral-color-20;
    stroke: $neutral-color-100;
}

.highcharts-column-series rect.highcharts-point {
    // rect to prevent stroke on 3D columns
    stroke: $background-color;
}

.highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
    fill-opacity: 0.45;
    stroke-linejoin: round;
    stroke: transparent;
}

.highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point-select {
    fill-opacity: 0.8;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $background-color;
}

.highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $background-color;
}

.highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: $neutral-color-10;
    transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
    stroke: $neutral-color-40;
    transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
    display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
    fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75;
}



/* Legend */
.highcharts-legend-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-legend-item text {
    fill: $neutral-color-80;
    font-weight: bold;
    font-size: $legend-font-size;
    cursor: pointer;
    stroke-width: 0;
}

.highcharts-legend-item:hover text {
    fill: $neutral-color-100;
}

.highcharts-legend-item-hidden * {
    opacity: 0.5;
    transition: fill 250ms;
}

.highcharts-legend-nav-active {
    fill: $highlight-color-100;
    cursor: pointer;
}

.highcharts-legend-nav-inactive {
    fill: $neutral-color-20;
}

.highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
}

/* Loading */
.highcharts-loading {
    position: absolute;
    background-color: $background-color;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
}

.highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
    fill: $neutral-color-100;
    fill-opacity: 0.05;
}

.highcharts-plot-line {
    fill: none;
    stroke: $neutral-color-40;
    stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
    fill: $background-color;
}

.highcharts-boxplot-median {
    stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
    stroke: $neutral-color-100;
}

.highcharts-gauge-series .highcharts-data-label-box {
    stroke: $neutral-color-20;
    stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
    fill: $neutral-color-100;
    stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
    stroke: $neutral-color-80;
    stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
    transition: fill 250ms, fill-opacity 250ms;
    fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition: fill 50ms, fill-opacity 50ms;
    fill-opacity: 1;
}

/* Highstock */
.highcharts-navigator-mask-outside {
    fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
    fill: $highlight-color-60; /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
}

.highcharts-navigator-outline {
    stroke: $neutral-color-20;
    fill: none;
}

.highcharts-navigator-handle {
    stroke: $neutral-color-20;
    fill: $neutral-color-5;
    cursor: ew-resize;
}

.highcharts-navigator-series {
    fill: $navigator-series-fill;
    stroke: $navigator-series-border;
}

.highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: $neutral-color-10;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: $neutral-color-40;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-scrollbar-thumb {
    fill: $neutral-color-20;
    stroke: $neutral-color-20;
    stroke-width: 1px;
}

.highcharts-scrollbar-button {
    fill: $neutral-color-10;
    stroke: $neutral-color-20;
    stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
    fill: $neutral-color-60;
}

.highcharts-scrollbar-rifles {
    stroke: $neutral-color-60;
    stroke-width: 1px;
}

.highcharts-scrollbar-track {
    fill: $scrollbar-track-background;
    stroke: $scrollbar-track-border;
    stroke-width: 1px;
}

.highcharts-button {
    fill: $highcharts-button-background;
    stroke: $highcharts-button-border;
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms;
}

.highcharts-button text {
    fill: $highcharts-button-text;
    font-weight: bold !important;
    font-size: 0.8rem;
}

.highcharts-button-hover {
    transition: fill 0ms;
    fill: $highcharts-button-hover-background;
    stroke: $highcharts-button-hover-border;
}

.highcharts-button-hover text {
    fill: $highcharts-button-hover-text;
}

.highcharts-button-pressed {
    font-weight: bold;
    fill: $highcharts-button-pressed-background;
    stroke: $highcharts-button-pressed-border;
}

.highcharts-button-pressed text {
    fill: $highcharts-button-pressed-text;
    font-weight: bold;
}

.highcharts-button-disabled text {
    fill: $gray-700;
}

.highcharts-range-selector-buttons .highcharts-button {
    stroke-width: $range-selector-button-border;
}

.highcharts-range-label rect {
    fill: none;
}

.highcharts-range-label text {
    fill: $neutral-color-60;
}

.highcharts-range-input rect {
    fill: none;
}

.highcharts-range-input text {
    fill: $range-selector-input-text;
}

.highcharts-range-input {
    stroke-width: 1px;
    stroke: $range-selector-input-border;
}

input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px; /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em; /* #4798 */
}

.highcharts-crosshair-label text {
    fill: $body-color;
    font-size: 1.1em;
    font-weight: bold !important;
}

.highcharts-crosshair-label .highcharts-label-box {
    fill: $body-bg !important;
}


.highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: $background-color;
    fill: $background-color;
    transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: $background-color;
    fill: $background-color;
}

.highcharts-flags-series .highcharts-point text {
    fill: $black;
    font-size: 0.9em;
    font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
    transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
    stroke: $neutral-color-20;
}

.highcharts-map-series .highcharts-point-hover {
    transition: fill 0ms, fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
    fill: none;
}

.highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
}

.highcharts-coloraxis {
    stroke-width: 0;
}

.highcharts-coloraxis-marker {
    fill: $neutral-color-40;
}

.highcharts-null-point {
    fill: $neutral-color-3;
}

/* 3d charts */
.highcharts-3d-frame {
    fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
    fill: $context-button-background; /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
}

.highcharts-contextbutton:hover {
    fill: $neutral-color-10;
    stroke: $neutral-color-10;
}

.highcharts-button-symbol {
    stroke: $neutral-color-60;
    stroke-width: 3px;
}

.highcharts-menu {
    border: 1px solid $neutral-color-40;
    background: $background-color;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: $neutral-color-80;
    cursor: pointer;
    transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
    background: $highlight-color-80;
    color: $background-color;
}

/* Drilldown module */
.highcharts-drilldown-point {
    cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: $highlight-color-100;
    font-weight: bold;
    text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
    font-weight: bold;
    font-size: 16px;
    fill: $neutral-color-100;
    text-align: center;
}

/* Drag-panes module */
.highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: $gray-400;
    stroke-width: 1px;
}

/* Bullet type series */
.highcharts-bullet-target {
    stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: $neutral-color-80;
}

.highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: $neutral-color-80;
}

/* Annotations module */
.highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: $neutral-color-100;
    fill: $neutral-color-100;
    fill-opacity: 0.75;
}

.highcharts-annotation-label text {
    fill: $neutral-color-10;
}

/* Gantt */
.highcharts-treegrid-node-collapsed, .highcharts-treegrid-node-expanded {
    cursor: pointer;
}

.highcharts-point-connecting-path {
    fill: none;
}

.highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px;
}

// Index Colors

// label, tooltip
.highcharts-color-0 {
    fill: $info;
}

// ohlc up bar
.highcharts-color-0 {
    stroke: $info !important;
}

.highcharts-data-label-color-0 {
    color: $info !important;
    fill: $info !important;
}

.highcharts-color-1 {
    fill: $red;
}

.highcharts-color-1 {
    stroke: $red !important;
}

.highcharts-data-label-color-1 {
    color: $red !important;
    fill: $red !important;
}

.highcharts-color-2 {
    fill: $green;
}

.highcharts-color-2 {
    stroke: $green !important;
}

.highcharts-data-label-color-2 {
    color: $green !important;
    fill: $green !important;
}

.highcharts-color-3 {
    fill: $yellow;
}

.highcharts-color-3 {
    stroke: $yellow !important;
}

.highcharts-data-label-color-3 {
    color: $yellow !important;
    fill: $yellow !important;
}

.highcharts-color-4 {
    fill: $indigo;
}

.highcharts-color-4 {
    stroke: $indigo !important;
}

.highcharts-data-label-color-4 {
    color: $indigo !important;
    fill: $indigo !important;
}

.highcharts-color-5 {
    fill: $pink;
}

.highcharts-color-5 {
    stroke: $pink !important;
}

.highcharts-data-label-color-5 {
    color: $pink !important;
    fill: $pink !important;
}

.highcharts-color-6 {
    fill: $purple;
}

.highcharts-color-6 {
    stroke: $purple !important;
}

.highcharts-data-label-color-6 {
    color: $purple !important;
    fill: $purple !important;
}

.highcharts-color-7 {
    fill: $dark;
}

.highcharts-color-7 {
    stroke: $dark !important;
}

.highcharts-data-label-color-7 {
    color: $dark !important;
    fill: $dark !important;
}

.highcharts-color-8 {
    fill: $teal;
}

.highcharts-color-8 {
    stroke: $teal !important;
}

.highcharts-data-label-color-8 {
    color: $teal !important;
    fill: $teal !important;
}

.highcharts-color-9 {
    fill: $orange;
}

.highcharts-color-9 {
    stroke: $orange !important;
}

.highcharts-data-label-color-9 {
    color: $orange !important;
    fill: $orange !important;
}

highcharts-negative {
    stroke: $red;
    fill: $red;
}


.highcharts-candlestick-series .highcharts-point {
    stroke: $body-bg;
    stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
    fill: $background-color;
}

.highcharts-ohlc-series .highcharts-point-down {
    stroke: $red;
    fill: $red;
}

.highcharts-candlestick-series .highcharts-point-up {
    stroke: $info !important;
    fill: $info;
    fill-opacity: 0.5;
}

.highcharts-candlestick-series .highcharts-point-down {
    stroke: $red !important;
    fill: $red;
}

// Side GUI Menu Charts
.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li {
    background-color: $body-bg !important;
}

.highcharts-bindings-wrapper li.highcharts-active > span.highcharts-menu-item-btn, .highcharts-bindings-wrapper li > span.highcharts-menu-item-btn:hover, .highcharts-bindings-wrapper .highcharts-arrow-wrapper > div:hover, .highcharts-bindings-wrapper li.highcharts-active, .highcharts-toggle-toolbar:hover {
    background-color: $gray-200 !important;
}

.highcharts-bindings-wrapper li:hover, .highcharts-submenu-item-arrow:hover {
    background-color: $gray-200 !important;
}

.highcharts-bindings-wrapper .highcharts-submenu-wrapper {
    background-color: $gray-200 !important;
}

.highcharts-toggle-toolbar {
    background-color: $gray-200 !important;
}

// GUI Popup - also in _widgets.scss

.highcharts-popup {
    background-color: rgba(255,255,255,0.95) !important;
    color: $body-color !important;
    font-family: $font-family-sans-serif !important;
    font-size: $font-size-base !important;
}

.highcharts-indicator-list li:hover {
    background-color: $gray-200 !important;
}

.highcharts-bindings-wrapper ul.highcharts-indicator-list, .highcharts-indicator-list, .highcharts-popup-rhs-col h3, .highcharts-popup-rhs-col, .highcharts-popup-close {
    color: $body-color !important;
}

.highcharts-popup-close {
    background-color: #fff !important;
}


.highcharts-tab-item-content {
    border: none !important;
}

.highcharts-popup button {
    background-color: $info !important;
    color: $body-bg !important;
}

.highcharts-popup input {
    border-color: $gray-300 !important;
    background: $background-color !important;
    color: $body-color !important;
}

.highcharts-tab-item.highcharts-tab-item-active {
    background-color: $info !important;
    color: $body-bg !important;
}

.highcharts-tab-item {
    background-color: $gray-500 !important;
    color: $body-color !important;
}


.highcharts-tab-disabled {
    background-color: $gray-200 !important;
    color: $gray-900 !important;
}
// Hidden Items - faulty GUI user action
.highcharts-separator, .highcharts-label-annotation, .highcharts-segment, .highcharts-elliott3, .highcharts-measure-xy, .highcharts-fibonacci, .highcharts-input-group, .highcharts-zoom-x {
    display: none !important;
}

.highcharts-toggle-toolbar {
    width: 32px !important;
    height: 32px !important;
}

.highcharts-range-selector-group {
    //	display: none !important;
    transform: none !important;
}

.highcharts-input-group {
    display: none !important;
}

// HighCharts Navigator

.highcharts-navigator-mask-inside {
    fill: rgba(52,82,255,0.5);
}
