//
// nav.scss
//

.nav-tabs, .nav-pills {
    > li {
        > a {
            color: $gray-700;
            font-weight: $label-font-weight;
            text-transform: uppercase;
        }
    }
}

.nav-pills {
    > a {
        color: $gray-700;
        font-weight: $label-font-weight;
    }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: $info;
    background-color: rgba($body-color, 0.1) !important;
}
//Navtab custom
.navtab-bg {
    .nav-link {
        background-color: $card-cap-bg;
        margin: 0 5px;
    }
}

.nav-tabs .nav-link {
    background-color: transparent;
    border: none !important;
    font-weight: $font-weight-bold;
}

.nav-tabs {
    border-bottom: 1px solid transparent !important;
}

.nav-link.active,
.nav-item.show .nav-link {
    border-bottom: 2px solid $info !important;
    color: $info !important;
}


// Nav bordered

.nav-bordered {
    border-bottom: 2px solid $info !important;

    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            border: 0 !important;
            padding: 10px 20px;
        }
    }

    a.active {
        border-bottom: 2px solid $primary !important;
    }
}

.tab-content {
    padding: 20px 0 0 0;
}

.tabbable .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}

.tabbable .nav-tabs .nav-link {
    white-space: nowrap;
}


@include media-breakpoint-down(md) {
    .nav-bordered li a {
        padding: 10px 20px;
    }
}
