//
// tables.scss
//

th {
    font-weight: $table-head-font-weight;
}


//Table centered (Custom)
.table-centered {
    td, th {
        vertical-align: middle !important;
    }
}

.table-borderless {
    th,
    td,
    thead th,
    tbody + tbody {
        border: 0;
        border: 0 !important;
    }
}

// striped tables

.table-striped tbody tr:nth-of-type(odd) {
    background-color: $table-striped-color;
}

.table thead th {
    font-family: $font-family-secondary;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.84rem;
    color: $gray-500;
}


.table-light, .table-light > td, .table-light > th {
    background-color: $gray-200;
    border-color: $gray-200;
}



.no-bg {
    background-color: transparent;
}

.data-row {
    cursor: pointer;
}


// Table

.table-nowrap {
    th, td {
        white-space: nowrap;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }

    td {
        border-top: 1px solid $gray-100;
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

.table-hover tbody tr:hover {
    background-color: $table-hover-bg !important;
}

// Custom FooTable
.footable.breakpoint > tbody > tr > td > span.footable-toggle {
    padding-right: 20px !important;
    font-size: 17px !important;
}

.footable-row-detail-row {
    line-height: 2.5em !important;
}

.footable-title {
    display: inline !important;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.84rem;
}


// overflow
.overflow-text {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
}

@include media-breakpoint-down(md) {
    .overflow-text {
        width: 255px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
    }
}

@include media-breakpoint-down(xs) {
    .overflow-text {
        width: 166px; //96px on standard bs breakpoint
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
    }
}

// Make table-responsive header sticky for new browsers - add background to row
//.table-responsive          { overflow-y: auto; max-height: 100vh;}
//.table-responsive  thead th { position: sticky; top: 0; }

// handle z-index conflict for modals and action 
.list-group-item-action:focus, .list-group-item-action:hover {
    z-index: inherit!important;
}