//
// modal.scss
//

// Title text within header
.modal-title {
    margin-top: 0;
}

.modal-header {
    padding: 0.5rem 1rem;
    border-color: transparent;
}

.modal-header span {
    display: block;
    font-size: 22px;
}

.close {
    font-size: 1.9rem;
    font-weight: 400 !important;
    color: $body-color !important;
    opacity: 1 !important;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%,0) !important;
    border-left: 1px solid $input-border-color;
    box-shadow: $box-shadow-lg;

    button.close {
        //position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {
        .modal-right {
            transform: translate(0,0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;

    .modal-content {
        border: 1px solid $gray-200;
        border-radius: 0px;
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
        box-shadow: $box-shadow-lg;
    }
}


// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .close {
        color: $white !important;
    }
}

.modal {
    // background: rgba(0,0,0,0.5) !important;
    backdrop-filter: blur(8px) !important;
}

// Footer
.modal-footer {
    border: none;
}

// Custom animation modal
.modal-demo {
    background-color: $white;
    width: 600px !important;
    border-radius: 4px;
    display: none;
    position: relative;
    overflow: hidden;

    .close {
        position: absolute;
        top: 15px;
        right: 25px;
        color: $light;
    }
}

@media (max-width: 768px) {
    .modal-demo {
        width: 96% !important;
    }

    .modal-header {
        display: flex;
    }

    .modal-header span {
        float: right;
    }
}

.custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: $primary;
    color: $white;
    text-align: left;
    margin: 0;
}

.custom-modal-text {
    padding: 20px;
}

.custombox-modal-wrapper {
    text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
    .close {
        top: 20px;
        z-index: 9999;
    }
}

// Scrollable height override
.modal-dialog-scrollable {
    max-height: 100% !important;
}
